import React from "react";

const DrawerLoading = () => {
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0 bg-white z-10 opacity-80 flex items-center justify-center h-full">
      <span class="loader-syncx"></span>
    </div>
  );
};

export default DrawerLoading;
