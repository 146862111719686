import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Badge, Button, Checkbox, Label, Tabs } from "flowbite-react";
import useCEP from "../../hooks/useCEP";
import { useForm } from "react-hook-form";
import { ADMIN, PAYMENTS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useRef, useState } from "react";
import CPFInput from "../inputs/CPFInput";
import PhoneInput from "../inputs/PhoneInput";
import { HiInformationCircle } from "react-icons/hi";
import SelectInput from "../inputs/SelectInput";
import { formatToReal } from "../../utils/utils";

const CompanyEditForm = ({
  company,
  fetchCompanies,
  isDrawerOpen,
  onClose,
}) => {
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingAddCredit, setIsSubmittingAddCredit] = useState(false);
  const [, setKey] = useState(0);
  const [error, setError] = useState(null);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    register: registerAddCredit,
    handleSubmit: handleSubmitAddCredit,
    reset: resetAddCredit,
    formState: { errors: errorsAddCredit },
  } = useForm();

  useEffect(() => {
    if (company) {
      Object.keys(company).forEach((key) => {
        setValue(key, company[key]);
      });
      setKey((prev) => prev + 1);
    }

    setError(null);
  }, [company, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      await axios.put(`${ADMIN.GET_COMPANY}/${company.id}`, data);

      onClose(false);

      fetchCompanies();

      setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitAddCredit = async (data) => {
    try {
      setIsSubmittingAddCredit(true);

      if (+data?.value <= 0) {
        throw new Error("Valor inválido!");
      }

      let bodyData = {
        payment: {
          customer: company.id,
          value: data.value,
          methodPayment: data.methodPayment,
        },
      };

      await axios.post(`${PAYMENTS.ADD_CREDIT_PIX}`, bodyData);

      onClose(false);

      fetchCompanies();

      setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsSubmittingAddCredit(false);
    }
  };

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  return (
    <>
      <Tabs
        aria-label="Tabs with underline"
        variant="underline"
        ref={tabsRef}
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        <Tabs.Item active title="Informações">
          <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome *"
                name="name"
                required={true}
                register={register}
                error={errors?.name && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <SelectInput
                register={register}
                label="Tipo de pagamento"
                name="typePayment"
              >
                <option value="PRE">Pré-Pago</option>
                <option value="POS">Pós-pago</option>
              </SelectInput>
            </div>
            <div className="mb-6">
              <PhoneInput
                type="text"
                label="Telefone *"
                name="phoneNumber"
                required={true}
                register={register}
                error={errors?.phoneNumber && "Este campo é obrigatório"}
              />
            </div>

            {company?.typeCustomer === "CNPJ" && (
              <div className="mb-6">
                <Input
                  type="text"
                  label="Razão Social"
                  name="socialReason"
                  required={false}
                  register={register}
                />
              </div>
            )}

            <div className="mb-6">
              <Input
                type="text"
                label="Nome do responsável"
                name="responsibleName"
                required={false}
                register={register}
              />
            </div>

            <div className="mb-6">
              <CPFInput
                type="text"
                label="CPF do responsável"
                name="responsibleCPF"
                required={false}
                register={register}
                error={errors?.cpf && "Este campo é obrigatório"}
                value={watch("responsibleCPF") || ""}
              />
            </div>

            <div className="mb-6">
              <SelectInput register={register} label="Cargo" name="position">
                <option value="SOCIO">Sócio/administrador</option>
                <option value="GERENTE">Gerente</option>
                <option value="ADM">Administrador</option>
              </SelectInput>
            </div>

            <div className="mb-6">
              <Input
                type="email"
                label="E-mail"
                name="email"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="ID do Asaas"
                name="asaas_id"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6 flex gap-2">
              <Input
                type="text"
                label="CEP"
                name="cep"
                required={false}
                register={register}
                className="flex-1"
              />
              <Button
                onClick={onClickSearchCEP}
                className="flex align-center whitespace-nowrap secondary"
                style={{ width: "220px", paddingTop: "4px" }}
                isProcessing={loadingCEP}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
              >
                Buscar CEP
              </Button>
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="Endereço"
                name="address"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6">
              <div className="flex gap-4">
                <Input
                  type="text"
                  label="Número"
                  name="addressNumber"
                  required={false}
                  register={register}
                  className="flex-1"
                />
                <Input
                  type="text"
                  label="Bairro"
                  name="addressNeighborhood"
                  required={false}
                  register={register}
                  className="flex-1"
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="flex gap-4">
                <Input
                  type="text"
                  label="Cidade"
                  name="addressCity"
                  required={false}
                  register={register}
                  className="flex-1"
                />
              </div>
            </div>

            <Button
              type="submit"
              className="w-full mb-48 md:mb-4 primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar cliente
            </Button>
            {error && (
              <Alert color="failure" icon={HiInformationCircle}>
                Erro ao atualizar cliente
              </Alert>
            )}
            <div className="mb-40 lg:mb-4"></div>
          </form>
        </Tabs.Item>
        {company?.typePayment === "PRE" && (
          <Tabs.Item title="Crédito">
            <form
              className="mb-6"
              onSubmit={handleSubmitAddCredit(onSubmitAddCredit)}
            >
              <div className="flex items-center gap-2">
                <span>Crédito atual:</span>{" "}
                <Badge className="w-fit">
                  {formatToReal(company?.credit || 0)}
                </Badge>{" "}
              </div>

              <Input
                type="number"
                label="Crédito"
                name="value"
                required={false}
                register={registerAddCredit}
                className="mt-6 mb-4"
                step="0.01"
                min="0"
              />
              <div className="mb-4">
                <SelectInput
                  register={registerAddCredit}
                  label="Método de pagamento"
                  name="methodPayment"
                >
                  <option value="PIX">Pix</option>
                  <option value="BOLETO">Boleto</option>
                  <option value="CARTAO">Cartão de crédito</option>
                </SelectInput>
              </div>
              <Button
                type="submit"
                className="w-full primary"
                isProcessing={isSubmittingAddCredit}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={isSubmittingAddCredit}
              >
                Adicionar crédito
              </Button>
              <small className="mb-48 md:mb-4 text-center block mt-2">
                Crédito disponível exclusivamente para clientes pré-pago.
              </small>
            </form>
          </Tabs.Item>
        )}
      </Tabs>
    </>
  );
};

export default CompanyEditForm;
