import React from "react";
import moment from "moment";

const HeaderQuery = () => {
  return (
    <div className="flex justify-between items-center -mt-2 mb-3">
      <img src="/assets/logo-azul.png" className="h-10" />
      <div>
        <span className="font-semibold mr-2">Data | Hora: </span>
        {moment(moment()).format("DD/MM/yyyy | HH:mm")}h
      </div>
    </div>
  );
};

export default HeaderQuery;
