import moment from "moment";
import React, { useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Card, Pagination, Table } from "flowbite-react";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { QUERIES } from "../../config/apiConfig";
import LastMonthsSelect from "../inputs/LastMonthsSelect";
import { formatToReal } from "../../utils/utils";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import EmptyTable from "./EmptyTable";

const ReportSimplifedTable = () => {
  const [period, setPeriod] = useState(moment().format("YYYY-MM"));
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  const {
    isLoading: isLoadingListQueriesTotal,
    error: errorListQueriesTotal,
    data: listQueriesTotal,
  } = useQuery(["listQueriesTotal", period], async () => {
    const { data } = await axios.get(
      `${QUERIES.GET_QUERIES_TOTAL}?period=${period}`
    );

    return data.data;
  });

  return (
    <>
      <FilterToolbar
        title="Selecione o mês:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "var(--bg-black)" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <LastMonthsSelect onChange={(e) => setPeriod(e.target.value)} />
        </div>
      </FilterToolbar>
      <div className="overflow-x-scroll mt-4">
        <div className="flex gap-3 pb-3" style={{ minWidth: "500px" }}>
          <Card
            className="w-full bg-gray-700 relative"
            style={{ minWidth: "150px" }}
          >
            <div className="text-sm md:text-md lg:text-lg text-white">
              Valor Total:
            </div>
            <div className="text-2xl text-white font-semibold flex gap-2 items-center">
              {!isLoadingListQueriesTotal ? (
                `${formatToReal(listQueriesTotal?.totalPriceSum || 0)}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <MdOutlineAttachMoney className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
          </Card>
          <Card className="w-full bg-gray-700 relative overflow-hidden">
            <div className="text-sm md:text-md lg:text-lg text-white">
              Total de consultas:
            </div>
            <div className="text-2xl text-white font-semibold">
              {!isLoadingListQueriesTotal ? (
                `${listQueriesTotal?.totalCount || 0}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
          </Card>
        </div>
      </div>
      <small
        className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
        style={{ fontSize: 10 }}
      >
        <span>Deslize para o lado para visualizar todas as informações</span>{" "}
        <FaArrowsLeftRight />
      </small>
      {listQueriesTotal?.counts?.length > 0 ? (
        <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
          <Table striped>
            {listQueriesTotal?.counts?.length > 0 ? (
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Produto/Consulta
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Quantidade
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Total
                </Table.HeadCell>
              </Table.Head>
            ) : (
              <></>
            )}

            <Table.Body className="divide-y">
              {!isLoadingListQueriesTotal ? (
                <>
                  {listQueriesTotal?.counts?.length > 0 && (
                    <>
                      {listQueriesTotal?.counts?.map((query, index) => (
                        <Table.Row
                          key={index}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {query.name}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {query.userQueryCount}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {formatToReal(query.totalPriceSum)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Table.Row
                      key={index}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                        <div
                          role="status"
                          className="max-w-sm animate-pulse inline"
                        >
                          <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <EmptyTable icon="search_off"> Nenhuma consulta realizada neste período.</EmptyTable>
      )}
    </>
  );
};

export default ReportSimplifedTable;
