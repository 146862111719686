let BASE_URL = "";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://app.acessopositivo.com.br";
}

BASE_URL += "/api";

const LOGIN = {
  POST_LOGIN: BASE_URL + "/login",
};

const CATEGORIES = {
  GET_ALL: "/categories",
};

const COMPANY = {
  GET_PERMISSION_QUERIES: "/company/permission/queries",
};

const USERS = {
  GET_USERS: "/users",
  GET_USER: "/user",
  GET_USER_CURRENT: "/user-current",
  GET_USER_QUERY_PERMISSIONS: "/user-query/permission",
};

const ADMIN = {
  GET_CUSTOMERS: "/admin/customers",
  GET_COMPANY_QUERIES: "/admin/company/queries",
  GET_QUERIES: "/admin/queries",
  NEW_CUSTOMER: "/admin/customer/new",
  GET_COMPANY_BY_INFO: "/admin/company/info",
  GET_COMPANY: "/admin/company",
  GET_COMPANY_QUERIES_HISTORY: "/admin/queries/company",
};

const QUERIES = {
  GET_DEFINE_RISCO: "/query/define-risco",
  GET_DEFINE_LIMITE: "/query/define-limite",
  GET_DEFINE_LIMITE_POSITIVO: "/query/define-limite-positivo",
  GET_ACERTA_ESSENCIAL: "/query/acerta-essencial",
  GET_ACERTA_COMPLETO: "/query/acerta-completo",
  GET_SCR_BACEN_SCORE: "/query/scr-bacen-score",
  GET_CADASTRAL_PF: "/query/cadastral-pf",
  GET_RESTRITIVA_PF: "/query/restritiva-pf",
  ET_CADASTRAL_PJ: "/query/cadastral-pj",
  GET_RESTRITIVA_PJ: "/query/restritiva-pj",
  GET_PROTESTO_NACIONAL: "/query/protesto-nacional",
  GET_REGULARIZE: "/query/regularize",
  GET_LOCALIZA_TELEFONE: "/query/localiza-telefone",
  GET_LOCALIZA_CPF: "/query/localiza-cpf",
  GET_LOCALIZA_CNPJ: "/query/localiza-cnpj",
  GET_LAST_QUERIES: "/query/last",
  GET_LAST_7_DAYS_COUNT_QUERIES: "/query/total/last/7",
  GET_QUERIES_TOTAL: "/query/total",
  GET_QUERIES_LIST: "/queries",
  UPLOAD_PDF: "/query/upload/pdf",
  DOWNLOAD_PDF: "/query/download/pdf",
  GET_DASHBOARD_INFOS: "/query/dashboard",
};

const AUDITS = {
  GET_AUDITS: "/audits",
};

const AVATAR = {
  UPLOAD: "/avatar/upload",
};

const PAYMENTS = {
  PIX: "/payment/pix",
  GET_ALL: "/invoices",
  ADD_CREDIT_PIX: "/payment/confirm/pix",
};

export {
  LOGIN,
  CATEGORIES,
  COMPANY,
  USERS,
  ADMIN,
  QUERIES,
  AUDITS,
  AVATAR,
  PAYMENTS,
  BASE_URL,
};
