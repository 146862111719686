import React, { useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import DefineRiscoQuery from "../queries/DefineRicoQuery";
import AcertaEssencialQuery from "../queries/AcertaEssencialQuery";
import ScrBacenScoreQuery from "../queries/ScrBacenScoreQuery";
import RestritivaPFQuery from "../queries/RestritivaPFQuery";
import DrawerLoading from "../DrawerLoading";
import { AiOutlineFileSearch } from "react-icons/ai";
import ProtestoNacionalQuery from "../queries/ProtestoNacionalQuery";
import RestritivaPJQuery from "../queries/RestritivaPJQuery";
import DefineLimiteQuery from "../queries/DefineLimiteQuery";
import DefineLimitePositivoQuery from "../queries/DefineLimitePositivoQuery";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";

const QueriesCreditTable = () => {
  const [selectedQuery, setSelectedQuery] = useState("");

  const queries = [
    {
      id: 1,
      name: "Define Risco",
      component: <DefineRiscoQuery queryId={1} />,
    },
    {
      id: 2,
      name: "Acerta Essencial CPF",
      component: <AcertaEssencialQuery queryId={2} />,
    },
    {
      id: 3,
      name: "Completa Plus CPF",
      component: <ScrBacenScoreQuery queryId={3} />,
    },
    {
      id: 5,
      name: "Restritiva CPF",
      component: <RestritivaPFQuery queryId={5} />,
    },
    {
      id: 7,
      name: "Restritiva PJ",
      component: <RestritivaPJQuery queryId={7} />,
    },
    {
      id: 8,
      name: "Protesto Nacional PF/PJ",
      component: <ProtestoNacionalQuery queryId={8} />,
    },
    {
      id: 9,
      name: "Define Limite PJ",
      component: <DefineLimiteQuery queryId={9} />,
    },
    {
      id: 10,
      name: "Define Limite Positivo PJ",
      component: <DefineLimitePositivoQuery queryId={10} />,
    },
  ];

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 1);
  });

  return (
    <div className="w-100 flex flex-col lg:flex-row gap-2 relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas de crédito</h2>
            {userQueryPermissions?.map((query, index) => (
              <Card
                key={index}
                className={`card-query ${
                  selectedQuery === query.id && "active"
                }`}
                onClick={() => setSelectedQuery(query.id)}
              >
                {query.name}
              </Card>
            ))}
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {queries.find((query) => query.id === selectedQuery)?.component}
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesCreditTable;
