import {
  Alert,
  Button,
  Card,
  Label,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskPhone } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";

const LocalizaTelefoneQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);

  const handleChange = (event) => {
    let valor = event.target.value;

    valor = maskPhone(valor);
    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_LOCALIZA_TELEFONE}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setData(null);
      setIsLoading(true);
      setError("");
      setErrorQuery("");
      if (info.length < 15) {
        setError("Número de telefone inválido!");
      } else {
        const { data: dados } = await fetchInfo();
        setData(dados.data.RESULTADO);
      }
    } catch (error) {
      setErrorQuery(
        typeof error?.response?.data?.message !== "object"
          ? error?.response?.data?.message
          : "Algum erro ocorreu com sua consulta"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Localiza Telefone
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="Telefone:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="15"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery />
              <div>
                <div className="title-syncx mt-5 mb-4">Histórico do número</div>
                {data?.CONTATO ? (
                  <Timeline>
                    {Array.isArray(data?.CONTATO) ? (
                      <>
                        {data?.CONTATO?.map((contato, index) => (
                          <Timeline.Item key={index}>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time>
                                Data referência:{" "}
                                {contato.DATA_REFER || "Sem info."}
                              </Timeline.Time>
                              <Timeline.Title>{contato.NOME}</Timeline.Title>
                              <Timeline.Body>
                                <div className="flex items-center gap-1">
                                  <span>Telefone:</span>
                                  <span>{contato.TELEFONE || "Sem info."}</span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span>Documento:</span>
                                  <span>{contato.DOC || "Sem info."}</span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span>Cidade:</span>
                                  <span>{contato.CIDADE || "Sem info."}</span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span>Tipo:</span>
                                  <span>{contato.TIPO || "Sem info."}</span>
                                </div>
                              </Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        ))}
                      </>
                    ) : (
                      <Timeline.Item>
                        <Timeline.Point />
                        <Timeline.Content>
                          <Timeline.Time>
                            Data referência:{" "}
                            {data?.CONTATO?.DATA_REFER || "Sem info."}
                          </Timeline.Time>
                          <Timeline.Title>{data?.CONTATO?.NOME}</Timeline.Title>
                          <Timeline.Body>
                            <div className="flex items-center gap-1">
                              <span>Telefone:</span>
                              <span>{data?.CONTATO?.TELEFONE || "Sem info."}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Documento:</span>
                              <span>{data?.CONTATO?.DOC || "Sem info."}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Cidade:</span>
                              <span>{data?.CONTATO?.CIDADE || "Sem info."}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Tipo:</span>
                              <span>{data?.CONTATO?.TIPO || "Sem info."}</span>
                            </div>
                          </Timeline.Body>
                        </Timeline.Content>
                      </Timeline.Item>
                    )}
                  </Timeline>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
            </Card>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default LocalizaTelefoneQuery;
