import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { isValidCPF, uploadPDF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import { BOA_VISTA_CONTANTS } from "../../utils/boa-vista-variables";
import HeaderQuery from "./HeaderQuery";

const AcertaEssencialQuery = ({ queryId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCPF(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_ESSENCIAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData({});
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data["essencial"]);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const upload = async () => {
      await uploadPDF(componentRef, info, queryId);
    };

    upload();
  }, [data]);

  const checkIfHasRestrictions = () => {
    if (
      data?.debitos?.registro === "S" ||
      data?.titulos_protestados?.registro === "S" ||
      data?.cheques_sustados_pelo_motivo_21?.registro === "S" ||
      data?.devolucoes_informadas_pelo_usuario?.registro === "S"
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Consulta Acerta Essencial CPF
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="14"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`} ref={componentRef}>
          {!isLoading ? (
            <>
              {checkIfHasRestrictions() ? (
                <Alert color="failure" icon={HiInformationCircle}>
                  <span className="font-medium">Atenção!</span> Constam
                  restrições para este CPF.
                </Alert>
              ) : (
                <Alert color="green" icon={FaRegCheckCircle}>
                  Não constam restrições para este CPF.
                </Alert>
              )}
              <Card className="mt-2">
                <HeaderQuery />
                <div className="title-syncx">Quadro de avisos</div>
                <div className="flex gap-2 flex-wrap">
                  <Card
                    className={`${
                      data?.resumo_ocorrencias_de_debitos?.registro === "N"
                        ? "bg-green-200"
                        : "bg-red-300"
                    } mb-3 flex-1`}
                  >
                    <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col items-center text-center">
                      <div>
                        <IoAlertCircleOutline fontSize={26} />
                      </div>
                      <div>
                        <div>Débitos:</div>
                        {data?.resumo_ocorrencias_de_debitos?.registro === "N"
                          ? "Nada consta"
                          : `R$ ${data?.resumo_ocorrencias_de_debitos?.valorAcomulado}`}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className={`${
                      data?.resumo_titulos_protestados?.registro === "N"
                        ? "bg-green-200"
                        : "bg-red-300"
                    } mb-3 flex-1`}
                  >
                    <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col items-center text-center">
                      <div>
                        <IoAlertCircleOutline fontSize={26} />
                      </div>
                      <div>
                        <div>Títulos protestados:</div>
                        {data?.resumo_titulos_protestados?.registro === "N"
                          ? "Nada consta"
                          : `${+data?.resumo_titulos_protestados
                              ?.total} ocorrências`}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className={`${
                      data?.resumo_devolucoes_informada_pelo_usuario
                        ?.registro == "N"
                        ? "bg-green-200"
                        : "bg-red-300"
                    } mb-3 flex-1`}
                  >
                    <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col items-center text-center">
                      <div>
                        <IoAlertCircleOutline fontSize={26} />
                      </div>
                      <div>
                        <div>Cheques sustados:</div>
                        {data?.resumo_devolucoes_informada_pelo_usuario
                          ?.registro == "N"
                          ? "Nada consta"
                          : `${+data?.resumo_devolucoes_informada_pelo_usuario
                              ?.totalDevolucoes} ocorrências`}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className={`${
                      data?.resumo_devolucoes_informadas_pelo_ccf?.registro ===
                      "N"
                        ? "bg-green-200"
                        : "bg-red-300"
                    } mb-3 flex-1`}
                  >
                    <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col items-center text-center">
                      <div>
                        <IoAlertCircleOutline fontSize={26} />
                      </div>
                      <div>
                        <div>Cheques sem fundos:</div>
                        {data?.resumo_devolucoes_informadas_pelo_ccf
                          ?.registro === "N"
                          ? "Nada consta"
                          : `${+data?.resumo_devolucoes_informadas_pelo_ccf
                              ?.totalDevolucoes} ocorrências`}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="title-syncx mt-5">Informações da pessoa:</div>
                <div className="flex flex-col md:flex-row gap-3 md:gap-20">
                  <div>
                    <div className="mb-3">
                      <span className="font-semibold">Situação: </span>
                      {BOA_VISTA_CONTANTS.SITUATION[
                        data?.identificacao.situacaoReceita
                      ] || "Indisponível"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nome completo: </span>
                      {data?.identificacao.nome}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Título de eleitor: </span>
                      {data?.identificacao.tituloEleitor}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Grau de instrução: </span>
                      {BOA_VISTA_CONTANTS.DEGREE_OF_EDUCATION[
                        data?.identificacao.grauInstrucao
                      ] || "Indisponível"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">
                        Data de nascimento:{" "}
                      </span>
                      {data?.identificacao.dataNascimento}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">RG: </span>
                      {data?.identificacao.numeroRG || "Não informado"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">
                        Número de dependentes:{" "}
                      </span>
                      {data?.identificacao.numeroDependentes}
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <span className="font-semibold">
                        Data de atualização:{" "}
                      </span>
                      {data?.identificacao.dataAtualizacao}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Sexo: </span>
                      {BOA_VISTA_CONTANTS.SEX[
                        data?.identificacao.sexoConsultado
                      ] || "Indisponível"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nome da mãe: </span>
                      {data?.identificacao.nomeMae}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Origem CPF: </span>
                      {BOA_VISTA_CONTANTS.DOCUMENT_ORIGIN[
                        data?.identificacao.regiaoCpf
                      ] || "Indisponível"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Estado Civil: </span>
                      {BOA_VISTA_CONTANTS.CIVIL_STATE[
                        data?.identificacao.estadoCivil
                      ] || "Indisponível"}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nacionalidade: </span>
                      {data?.identificacao.cidadeNascimento || "Não informado"}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="title-syncx mt-5">
                    Score de risco |{" "}
                    {data?.score_classificacao_varios_modelos[0]?.nomeScore.toLowerCase()}
                  </div>
                </div>
                <div className="flex flex-col gap-2 md:flex-row items-center">
                  <div className="w-full md:w-3/5 p-4">
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "radialBar",
                          offsetY: -20,
                          sparkline: {
                            enabled: true,
                          },
                        },
                        plotOptions: {
                          radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                              background: "#e7e7e7",
                              strokeWidth: "97%",
                              margin: 5, // margin is in pixels
                              dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                              },
                            },
                            dataLabels: {
                              name: {
                                show: false,
                              },
                              value: {
                                offsetY: -2,
                                fontSize: "22px",
                                formatter: function (val) {
                                  return val * 10;
                                },
                              },
                            },
                          },
                        },
                        grid: {
                          padding: {
                            top: -10,
                          },
                        },
                        fill: {
                          type: "solid",
                          gradient: {
                            shade: "light",
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91],
                          },
                          colors: (() => {
                            const score =
                              +data?.score_classificacao_varios_modelos[0]
                                ?.score;
                            if (score >= 800) {
                              return "#01B000";
                            } else if (score >= 600) {
                              return "#8DED01";
                            } else if (score >= 400) {
                              return "#FDF700";
                            } else if (score >= 200) {
                              return "#FFBC00";
                            } else {
                              return "#E54B00";
                            }
                          })(),
                        },
                      }}
                      series={[
                        +data?.score_classificacao_varios_modelos[0]?.score /
                          10,
                      ]}
                      type="radialBar"
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2">
                      <div className="text-red-500">
                        Probabilidade de indadimplência ={" "}
                        {
                          +data?.score_classificacao_varios_modelos[0]
                            ?.probabilidade
                        }
                      </div>{" "}
                      {data?.score_classificacao_varios_modelos[0]?.texto}
                    </div>
                  </div>
                </div>
                <small>
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como justificativa, pelo concedente do crédito,
                  para a tomada da referida decisão.
                </small>
                <div>
                  <div className="title-syncx mt-5 mb-3">Renda Presumida</div>
                  <div className="mb-3">
                    <span className="font-semibold">Faixa: </span>
                    {data?.score_classificacao_varios_modelos[1]?.texto}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Descrição: </span>
                    {
                      data?.score_classificacao_varios_modelos[1]
                        ?.descricaoNatureza
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Observações: </span>
                    {data?.score_classificacao_varios_modelos[1]?.texto2}
                  </div>
                </div>

                <div>
                  <div className="title-syncx mt-5 mb-4">
                    Pendências financeiras
                  </div>

                  {data?.resumo_ocorrencias_de_debitos?.registro === "S" ? (
                    <>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Informante
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Situação
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Contrato
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(data?.debitos) ? (
                              <>
                                {data?.debitos?.map((debito, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {debito.dataOcorrencia}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {debito.informante}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {debito.cidade}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {debito.situacao}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {debito.contrato}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {debito.moeda}
                                      {debito.valor}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {data?.debitos?.dataOcorrencia}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.debitos?.informante}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.debitos?.cidade}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.debitos?.situacao}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.debitos?.contrato}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.debitos?.moeda}
                                  {data?.debitos?.valor}
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}

                  <div className="title-syncx mt-10 mb-4">
                    Títulos protestados
                  </div>

                  {data?.resumo_titulos_protestados?.registro === "S" ? (
                    <>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cartório
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Tipo da ocorrência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(data?.titulos_protestados) ? (
                              <>
                                {data?.titulos_protestados?.map(
                                  (titulo, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {titulo.dataOcorrencia}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {titulo.cartorio}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {titulo.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {titulo.tipoOcorrencia}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {titulo.moeda}
                                        {titulo.valor}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {data?.titulos_protestados?.dataOcorrencia}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.titulos_protestados?.cartorio}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.titulos_protestados?.cidade}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.titulos_protestados?.tipoOcorrencia}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.titulos_protestados?.moeda}
                                  {data?.titulos_protestados?.valor}
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div className="title-syncx mt-10 mb-1">Cheques sustados</div>

                {data?.resumo_devolucoes_informada_pelo_usuario?.registro ===
                "S" ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome do banco
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Agência
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Documento
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(
                            data?.cheques_sustados_pelo_motivo_21
                          ) ? (
                            <>
                              {data?.cheques_sustados_pelo_motivo_21?.map(
                                (cheque, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {cheque.nome}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.nomeBanco}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.agencia}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.numeroDocumento}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {data?.cheques_sustados_pelo_motivo_21?.nome}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {
                                  data?.cheques_sustados_pelo_motivo_21
                                    ?.nomeBanco
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.cheques_sustados_pelo_motivo_21?.agencia}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {
                                  data?.cheques_sustados_pelo_motivo_21
                                    ?.numeroDocumento
                                }
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}

                <div className="title-syncx mt-10 mb-1">Cheques sem fundos</div>

                {data?.resumo_devolucoes_informadas_pelo_ccf?.registro ===
                "S" ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome do banco
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Agência
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Documento
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(
                            data?.relacao_devolucoes_informadas_pelo_ccf
                          ) ? (
                            <>
                              {data?.relacao_devolucoes_informadas_pelo_ccf?.map(
                                (cheque, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {cheque.nome}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.nomeBanco}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.agencia}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.numeroDocumento}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {
                                  data?.relacao_devolucoes_informadas_pelo_ccf
                                    ?.nome
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {
                                  data?.relacao_devolucoes_informadas_pelo_ccf
                                    ?.nomeBanco
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {
                                  data?.relacao_devolucoes_informadas_pelo_ccf
                                    ?.agencia
                                }
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {
                                  data?.relacao_devolucoes_informadas_pelo_ccf
                                    ?.numeroDocumento
                                }
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}

                <div>
                  <div className="title-syncx mt-10 mb-4">
                    Últimas consultas
                  </div>

                  {data?.resumoConsultas_anteriores_90_dias?.registro ===
                  "S" ? (
                    <>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Informante
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Tipo de ocorrência
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.consultas_anteriores?.map(
                              (consult, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {consult.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {consult.informante}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {consult.tipoOcorrencia}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
              </Card>
            </>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default AcertaEssencialQuery;
