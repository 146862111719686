import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { isValidCNPJ, uploadPDF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ } from "../../utils/masks";
import moment from "moment";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";

const RestritivaPJQuery = ({ queryId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCNPJ(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_RESTRITIVA_PJ}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData({});
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(
          dados.data["ReportPJResponseEx"].Response.Records.ReportPJOutput[0]
        );
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const upload = async () => {
      await uploadPDF(componentRef, info, queryId);
    };

    upload();
  }, [data]);

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Consulta Restritiva PJ
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`} ref={componentRef}>
          {!isLoading ? (
            <Card>
              <HeaderQuery />

              <div className="title-syncx mt-5">Quadro de avisos</div>
              <div className="flex gap-2 flex-wrap">
                <Card
                  className={`${
                    !data?.Negative?.Apontamentos?.Apontamento?.length > 0
                      ? "bg-green-200"
                      : "bg-red-300"
                  } mb-3 flex-1`}
                >
                  <div className="flex gap-2 items-center text-gray-600 font-semibold flex-col text-center">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Negativações: <br />
                      {!data?.Negative?.Apontamentos?.Apontamento?.length > 0
                        ? "Nada consta"
                        : `Possui pendências negativas`}
                    </div>
                  </div>
                </Card>
                <Card
                  className={`${
                    !data?.Negative?.CcfApontamentos?.CcfApontamento?.length > 0
                      ? "bg-green-200"
                      : "bg-red-300"
                  } mb-3 flex-1`}
                >
                  <div className="flex gap-2 items-center text-gray-600 font-semibold flex-col text-center">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Cheques sem fundos: <br />
                      {!data?.Negative?.CcfApontamentos?.CcfApontamento
                        ?.length > 0
                        ? "Nada consta"
                        : `Constam pendências`}
                    </div>
                  </div>
                </Card>
                <Card
                  className={`${
                    !data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                      ?.length > 0
                      ? "bg-green-200"
                      : "bg-red-300"
                  } mb-3 flex-1`}
                >
                  <div className="flex gap-2 items-center text-gray-600 font-semibold flex-col text-center">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Ações judiciais: <br />
                      {!data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                        ?.length > 0
                        ? "Nada consta"
                        : `Constam ações judiciais`}
                    </div>
                  </div>
                </Card>
                <Card
                  className={`${
                    !data?.Protests?.Protest.length > 0
                      ? "bg-green-200"
                      : "bg-red-300"
                  } mb-3 flex-1`}
                >
                  <div className="flex gap-2 items-center text-gray-600 font-semibold flex-col text-center">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Protestos: <br />
                      {!data?.Protests?.Protest.length > 0
                        ? "Nada consta"
                        : `Possui protestos`}
                    </div>
                  </div>
                </Card>
              </div>

              <div className="title-syncx mt-5">Negativações</div>
              {data?.Negative?.Apontamentos?.Apontamento?.length > 0 ? (
                <>
                  <div className="overflow-x-auto shadow-lg rounded-lg">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-red-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Empresa
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Contrato
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Cidade:
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Valor:
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.Negative?.Apontamentos?.Apontamento?.map(
                          (apontamento, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {apontamento.DateOccurred.Day}/
                                {apontamento.DateOccurred.Month}/
                                {apontamento.DateOccurred.Year}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {apontamento.CompanyName}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {apontamento.ContractNumber}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {apontamento.Address?.City}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                R$ {apontamento.Amount}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}

              <div className="title-syncx mt-5">Cheques sem fundos</div>
              {data?.Negative?.CcfApontamentos?.CcfApontamento?.length > 0 ? (
                <>
                  <div className="overflow-x-auto shadow-lg rounded-lg">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-red-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Banco
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Documento
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.Negative?.CcfApontamentos?.CcfApontamento?.map(
                          (ccf, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {ccf.DateLastBounce?.Day}/
                                {ccf.DateLastBounce?.Month}/
                                {ccf.DateLastBounce?.Year}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ccf.ReportingNameBank}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ccf.CpfCnpj}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}

              <div className="title-syncx mt-5">Ações judiciais</div>
              {data?.Negative?.LawSuitApontamentos?.LawSuitApontamento?.length >
              0 ? (
                <>
                  <div className="overflow-x-auto shadow-lg rounded-lg">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-red-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Fórum
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Tipo
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Cidade:
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-red-300">
                          Valor:
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.Negative?.LawSuitApontamentos?.LawSuitApontamento?.map(
                          (acao, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {acao.DateProcessed?.Day}/
                                {acao.DateProcessed?.Month}/
                                {acao.DateProcessed?.Year}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {acao.Forum}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {acao.ProcessType}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {acao.City}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                R$ {acao.AmountLawsuit}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}

              <div>
                <div className="title-syncx mt-5 mb-4">
                  Histórico de protestos
                </div>
                {data?.Protests?.Protest?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Situação
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Protests?.Protest?.map((protest, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {moment(protest.data_consulta).format(
                                  "DD/MM/yyyy"
                                )}
                              </Table.Cell>
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {protest?.situacao || "-"}
                              </Table.Cell>
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                R$ {protest?.valor_protestados_total || "-"}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <div className="title-syncx mt-5 mb-4">
                  Histórico de consultas
                </div>
                {data?.Inquiries?.InquiryDetails?.InquiryDetail?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Segmento
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Quantidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data da consulta
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Inquiries?.InquiryDetails?.InquiryDetail?.map(
                            (inquiry, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                  {inquiry.Segment}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {inquiry.InquiryCount}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {inquiry.DateInquiry?.Day}/
                                  {inquiry.DateInquiry?.Month}/
                                  {inquiry.DateInquiry?.Year}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>
            </Card>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default RestritivaPJQuery;
