import { AiOutlineLoading } from "react-icons/ai";
import { Accordion, Alert, Button } from "flowbite-react";
import { useForm } from "react-hook-form";
import { ADMIN, CATEGORIES, PROFESSIONALS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { resetValuesForm } from "../../utils/utils";
import { HiInformationCircle } from "react-icons/hi";
import { useQuery } from "react-query";
import Input from "../inputs/Input";
import DrawerLoading from "../DrawerLoading";

const CompanyQueriesForm = ({
  categories,
  company,
  fetchCompanies,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [isQueryEnabledCompanyQueries, setIsQueryEnabledCompanyQueries] =
    useState(false);
  const {
    isLoadingCompanyQueries,
    errorCompanyQueries,
    data: companyQueries,
    refetch,
  } = useQuery(["companyQueries", company], async () => {
    const { data: items } = await axios.get(
      `${ADMIN.GET_COMPANY_QUERIES}/${company.id}`
    );
    return items.data;
  });

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
    setIsQueryEnabledCompanyQueries(true);
    categories?.forEach((category, catIdx) => {
      category?.Queries?.forEach((query, index) => {
        setValue(`categories[${catIdx}].queries[${index}].QueryId`, query.id);

        const companyQuery =
          companyQueries?.find((cq) => cq.QueryId === query.id) || null;

        if (companyQuery) {
          setValue(`categories[${catIdx}].queries[${index}].isActive`, true);
          setValue(
            `categories[${catIdx}].queries[${index}].price`,
            companyQuery.price
          );
        } else {
          setValue(
            `categories[${catIdx}].queries[${index}].price`,
            query.price
          );
        }
      });
    });
  }, [company, companyQueries]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      let newArrayQueries = [];

      data.categories.forEach((cat) => {
        cat.queries.forEach((query) => {
          newArrayQueries = [...newArrayQueries, query];
        });
      });

      data.queries = newArrayQueries.filter((query) => query.isActive);

      await axios.put(
        `${ADMIN.GET_COMPANY_QUERIES}/${company.id}`,
        data.queries
      );

      onClose(false);

      //fetchProfessionals();
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="font-semibold mb-5">Cliente: {company?.name}</div>
        {categories?.length > 0 ? (
          <>
            <Accordion style={{ maxWidth: "600px" }}>
              {categories?.map((category, idxCat) => (
                <Accordion.Panel key={idxCat}>
                  <Accordion.Title>{category.name}</Accordion.Title>
                  <Accordion.Content>
                    <div className="flex flex-col gap-4">
                      {category?.Queries?.length > 0 ? (
                        <>
                          {category.Queries.map((query, index) => (
                            <div key={index}>
                              <div className="flex justify-between items-center">
                                <label className="flex items-center cursor-pointer gap-3">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sr-only peer"
                                      name={`categories[${idxCat}].queries[${index}].isActive`}
                                      {...register(
                                        `categories[${idxCat}].queries[${index}].isActive`
                                      )}
                                      // checked={watch(
                                      //   `categories[${idxCat}].queries[${index}].isActive`
                                      // )}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                                  </div>
                                  <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {query.name}
                                  </span>
                                </label>
                                <Input
                                  type="number"
                                  step="0.01"
                                  register={register}
                                  name={`categories[${idxCat}].queries[${index}].price`}
                                  className="w-30"
                                  label="Preço"
                                />
                              </div>
                              <hr className="mt-4" />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="text-sm md:text-md text-center">
                          Não há nenhuma consulta cadastrada nesta categoria
                        </div>
                      )}
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
            <Button
              type="submit"
              className="w-full mb-48 md:mb-4 primary mt-6"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar permissões
            </Button>

            {error && (
              <Alert color="failure" icon={HiInformationCircle}>
                Erro ao atualizar permissões do cliente
              </Alert>
            )}
          </>
        ) : (
          <div className="text-sm md:text-md text-center">
            Não há nenhuma categoria cadastrada. <br />
            Comece cadastrando as categorias e os permissões primeiro.
          </div>
        )}
      </form>

      {isLoadingCompanyQueries && <DrawerLoading />}
    </>
  );
};

export default CompanyQueriesForm;
