import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ, maskCPF } from "../../utils/masks";
import moment from "moment";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";

const ProtestoNacionalQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    if (valor.length <= 11) {
      valor = maskCPF(valor);
    } else {
      valor = maskCNPJ(valor);
    }

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setData({});
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data } = await fetchInfo();
        setData(data.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Protesto Nacional PF/PJ
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF/CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery />

              <div className="title-syncx mt-5">Quadro de avisos</div>
              <div>
                <Card
                  className={`${
                    data?.qtdTitulos === 0 ? "bg-green-200" : "bg-red-300"
                  } mb-3`}
                >
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Protestos:{" "}
                      {data?.qtdTitulos === 0
                        ? "Nada consta"
                        : `Possui ${data?.qtdTitulos} protestos`}
                    </div>
                  </div>
                </Card>
              </div>
              <div>
                <div className="title-syncx mb-4">Protestos</div>
                {data?.cartorio?.length > 0 ? (
                  <>
                    {data?.cartorio?.map((cartorio, index) => (
                      <div key={index}>
                        {cartorio.dadosCartorio.map((dados, indexDados) => (
                          <div
                            className="rounded shadow-md border border-gray-200 mb-4 p-3 pb-5 flex flex-col gap-1 bg-gray-100"
                            key={indexDados}
                          >
                            <div className="font-semibold">
                              Nome do cartório:{" "}
                              <span className="capitalize font-normal">
                                {dados.nomeCartorio.toLowerCase()}
                              </span>
                            </div>
                            <div className="font-semibold">
                              Endereço:{" "}
                              <span className="capitalize font-normal">
                                {dados.endereco.toLowerCase()}
                              </span>
                            </div>
                            <div className="font-semibold">
                              Cidade:{" "}
                              <span className="capitalize font-normal">
                                {dados.cidade.toLowerCase()}
                              </span>
                            </div>
                            <div className="font-semibold">
                              Telefone:{" "}
                              <span className="font-normal">
                                {dados.telefone}
                              </span>
                            </div>
                            <div className="font-semibold">
                              Qtd. títulos: {dados.qtdTitulos}
                            </div>
                            {dados?.titulos?.length > 0 && (
                              <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                                <Table striped>
                                  <Table.Head>
                                    <Table.HeadCell className="text-center bg-red-300">
                                      Data
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-center bg-red-300">
                                      Nome Apresentante
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-center bg-red-300">
                                      Nome Cedente
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-center bg-red-300">
                                      Valor
                                    </Table.HeadCell>
                                  </Table.Head>

                                  <Table.Body className="divide-y">
                                    {dados?.titulos?.map(
                                      (titulo, indexTitulo) => (
                                        <Table.Row
                                          key={indexTitulo}
                                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        >
                                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                            {titulo.dataProtesto}
                                          </Table.Cell>
                                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                            {titulo.nomeApresentante}
                                          </Table.Cell>
                                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                            {titulo.nomeCedente}
                                          </Table.Cell>
                                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                            R$ {titulo.valorProtestado}
                                          </Table.Cell>
                                        </Table.Row>
                                      )
                                    )}
                                  </Table.Body>
                                </Table>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <Card className={`bg-green-200  mb-3`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <FaRegCheckCircle fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>
            </Card>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default ProtestoNacionalQuery;
