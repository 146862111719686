import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { isValidCNPJ, isValidCPF, uploadPDF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { handleDownloadPdf } from "../../utils/pdf-exporter";
import moment from "moment";
import { useApplication } from "../../contexts/ApplicationContext";
import { maskCNPJ } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";

const DefineRiscoQuery = ({ queryId }) => {
  const { user } = useApplication();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCNPJ(valor);

    setInfo(valor);
  };

  const fetchDefineRisco = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_RISCO}/${info.replace(/\D/g, "")}`
    );
  };

  const handleClickDownloadPDF = async () => {
    const { data } = await axios.get(
      `${QUERIES.DOWNLOAD_PDF}?fileName=${moment().format(
        "DDMMYYYY"
      )}-${info.replace(/\D/g, "")}-${user.id}`
    );
    window.open(data.fileUrl);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData({});
        setIsLoading(true);
        setError("");
        setErrorData("");
        const { data: dados } = await fetchDefineRisco();
        setData(dados.data["defineRisco"]);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const upload = async () => {
      await uploadPDF(componentRef, info, queryId);
    };

    upload();
  }, [data]);

  const checkIfHasRestrictions = () => {
    if (
      data?.pendencias_restricoes?.mensagem === "Nada Consta." ||
      data?.cheques_sem_fundo?.mensagem === "Nada Consta." ||
      data?.cheques_sustados?.mensagem === "Nada Consta." ||
      data?.cheques_devolvidos_informados_usuario?.mensagem === "Nada Consta."
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Consulta Define Risco
      </div>
      <Card className="mb-4 card-query">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className="shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
            {!errorData && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`} ref={componentRef}>
          {!isLoading ? (
            <>
              {checkIfHasRestrictions() ? (
                <Alert color="failure" icon={HiInformationCircle}>
                  <span className="font-medium">Atenção!</span> Constam
                  restrições para este CNPJ.
                </Alert>
              ) : (
                <Alert color="green" icon={FaRegCheckCircle}>
                  Não constam restrições para este CNPJ.
                </Alert>
              )}
              <Card className="mt-2">
                <HeaderQuery />
                <div className="title-syncx">Quadro de avisos</div>
                <div className="flex gap-2 flex-wrap">
                  {+data?.cheques_sem_fundo?.total_cheques_sem_fundos > 0 ? (
                    <Card className={`bg-red-300 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <IoAlertCircleOutline fontSize={24} />
                        </div>
                        <div>
                          Cheques sem fundos: <br />
                          {`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} ocorrências`}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card className={`bg-green-200 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>
                          Cheques sem fundos: <br />
                          Nada consta
                        </div>
                      </div>
                    </Card>
                  )}

                  {+data?.cheques_sustados?.total_cheques_sustados > 0 ? (
                    <Card className={`bg-red-300 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <IoAlertCircleOutline fontSize={24} />
                        </div>
                        <div>
                          Cheques sustados: <br />
                          {`${data?.cheques_sustados?.total_cheques_sustados} ocorrências`}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card className={`bg-green-200 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>
                          Cheques sustados: <br />
                          Nada consta
                        </div>
                      </div>
                    </Card>
                  )}

                  {+data?.pendencias_restricoes?.total_pendencias > 0 ? (
                    <Card className={`bg-red-300 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <IoAlertCircleOutline fontSize={24} />
                        </div>
                        <div>
                          Pendências financeiras: <br />
                          {`${data?.pendencias_restricoes?.total_pendencias} ocorrências`}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card className={`bg-green-200 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>
                          Pendências financeiras: <br />
                          Nada consta
                        </div>
                      </div>
                    </Card>
                  )}

                  {+data?.protestos?.total_protestos > 0 ? (
                    <Card className={`bg-red-300 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <IoAlertCircleOutline fontSize={24} />
                        </div>
                        <div>
                          Protestos: <br />
                          {`${data?.protestos?.total_protestos} ocorrências`}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card className={`bg-green-200 mb-3 flex-1`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold flex flex-col text-center">
                        <div>
                          <FaRegCheckCircle fontSize={20} />
                        </div>
                        <div>
                          Protestos: <br />
                          Nada consta
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
                <div className="title-syncx mt-5">Informações da empresa</div>
                <div className="flex flex-col md:flex-row gap-3 md:gap-20">
                  <div>
                    <div className="mb-3 flex gap-2 items-center">
                      <span className="font-semibold">Situação: </span>
                      <Badge
                        className="w-fit"
                        color={
                          data?.identificacao_basico?.situacao_cnpj === "Ativo"
                            ? "success"
                            : "gray"
                        }
                      >
                        {data?.identificacao_basico?.situacao_cnpj}
                      </Badge>
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Razão Social: </span>
                      {data?.identificacao_basico?.razao_social}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Nome fantasia: </span>
                      {data?.identificacao_basico?.nome_fantasia}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">CNPJ: </span>
                      {data?.identificacao_basico?.cnpj}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Fundação: </span>
                      {data?.identificacao_basico?.data_fundacao}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">CNAE Principal: </span>
                      {data?.identificacao_basico?.ramo_atividade.atividade}
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <span className="font-semibold">
                        Inscr. Estadual/UF:{" "}
                      </span>
                      {data?.identificacao_basico?.inscricao_estadual}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Natureza jurídica: </span>
                      {data?.identificacao_basico?.natureza_juridica}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Ençereco: </span>
                      {data?.localizacao_basico?.logradouro}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Bairro: </span>
                      {data?.localizacao_basico?.bairro}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Cidade/UF: </span>
                      {data?.localizacao_basico?.uf}
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">CEP: </span>
                      {data?.localizacao_basico?.cep}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="title-syncx mt-5">Score de risco</div>
                </div>
                <div className="flex flex-col gap-2 md:flex-row items-center">
                  <div className="w-full md:w-3/5 p-4">
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "radialBar",
                          offsetY: -20,
                          sparkline: {
                            enabled: true,
                          },
                        },
                        plotOptions: {
                          radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                              background: "#e7e7e7",
                              strokeWidth: "97%",
                              margin: 5, // margin is in pixels
                              dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                              },
                            },
                            dataLabels: {
                              name: {
                                show: false,
                              },
                              value: {
                                offsetY: -2,
                                fontSize: "22px",
                                formatter: function (val) {
                                  return val * 10;
                                },
                              },
                            },
                          },
                        },
                        grid: {
                          padding: {
                            top: -10,
                          },
                        },
                        fill: {
                          type: "solid",
                          gradient: {
                            shade: "light",
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91],
                          },
                          colors: (() => {
                            const score = +data?.score?.score_pj.score;
                            if (score >= 800) {
                              return "#01B000";
                            } else if (score >= 600) {
                              return "#8DED01";
                            } else if (score >= 400) {
                              return "#FDF700";
                            } else if (score >= 200) {
                              return "#FFBC00";
                            } else {
                              return "#E54B00";
                            }
                          })(),
                        },
                      }}
                      series={[+data?.score?.score_pj.score / 10]}
                      type="radialBar"
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2">
                      <div className="text-red-500">
                        Probabilidade de indadimplência ={" "}
                        {data?.score?.score_pj?.probabilidade}
                      </div>{" "}
                      {data?.score?.score_pj?.texto}
                    </div>
                  </div>
                </div>
                <small>
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como justificativa, pelo concedente do crédito,
                  para a tomada da referida decisão.
                </small>
                <div>
                  <div className="title-syncx mt-5 mb-4">
                    Pendências financeiras
                  </div>
                  {data?.pendencias_restricoes?.ultimas_ocorrencias
                    ?.pendencia_restricao?.length > 0 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold">Quantidade: </span>
                          <Badge color="failure" className="w-fit">
                            {data?.pendencias_restricoes?.total_pendencias}
                          </Badge>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold">
                            Último vencimento:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {data?.pendencias_restricoes?.data_maior}
                          </Badge>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold">Valor Total: </span>
                          <Badge color="failure" className="w-fit">
                            R$ {data?.pendencias_restricoes?.valor}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Vencimento
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Informante
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Tipo:
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade:
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Documento origem:
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.pendencias_restricoes.ultimas_ocorrencias
                                ?.pendencia_restricao
                            ) ? (
                              <>
                                {data?.pendencias_restricoes.ultimas_ocorrencias?.pendencia_restricao?.map(
                                  (ocorrencia, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {ocorrencia.data_debito}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.informante}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        R$ {ocorrencia.valor}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.tipo}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.documento_origem}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.data_debito
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.informante
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R${" "}
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.valor
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.tipo
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.cidade
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.documento_origem
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <div className="title-syncx mt-5 mb-4">Protestos</div>
                  {!!data?.protestos?.total_protestos ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.protestos?.total_protestos}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Primeiro valor:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.protestos?.valor_primeiro}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Valor Total:{" "}
                          </span>
                          <Badge color="failure">
                            R$ {data?.protestos?.valor_total}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cartório
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Estado
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Vencimento:
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.protestos?.ultimas_ocorrencias?.protesto
                            ) ? (
                              <>
                                {data?.protestos?.ultimas_ocorrencias?.protesto.map(
                                  (protesto, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {protesto.data}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        R$ {protesto.valor}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.cartorio}º cartório
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.estado}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.vencimento}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.data
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  R${" "}
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.valor
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cartorio
                                  }
                                  º cartório
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cidade
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.estado
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.vencimento
                                  }
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <div className="title-syncx mt-5 mb-4">
                    Cheques sem fundos
                  </div>
                  {!!data?.cheques_sem_fundo?.total_cheques_sem_fundos ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.cheques_sem_fundo?.total_cheques_sem_fundos}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Total de nomes relacionados:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.cheques_sem_fundo?.total_nomes_relacionados}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Agência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Nome do banco
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Quantidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Motivo
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.cheques_sem_fundo?.ultimas_ocorrencias
                                ?.cheque_sem_fundo
                            ) ? (
                              <>
                                {data?.cheques_sem_fundo?.ultimas_ocorrencias?.cheque_sem_fundo.map(
                                  (cheque, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {cheque.data_ultimo}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.agencia}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.nome_banco}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.quantidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.motivo}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.data_ultimo
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.agencia
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.nome_banco
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.quantidade
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.motivo
                                  }
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <div className="title-syncx mt-5 mb-4">Cheques sustados:</div>
                  {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado
                    ?.length > 0 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6 flex-wrap">
                        <div>
                          <span className="font-semibold">Período: </span>
                          {data?.cheques_sustados?.periodo_inicial} até
                          {data?.cheques_sustados?.periodo_final}
                        </div>
                        <div>
                          <span className="font-semibold">Quantidade: </span>
                          {
                            data?.cheques_devolvidos_informados_usuario
                              ?.quantidade_total
                          }
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Banco
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Agência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Cheque
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado.map(
                              (cheque, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {cheque.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.banco}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.agencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.cheques}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <div className="title-syncx mt-5 mb-4">
                    Cheques devolvidos
                  </div>
                  {data?.cheques_devolvidos_informados_usuario
                    ?.ultimas_ocorrencias?.cheque_devolvido_informado_usuario
                    ?.length > 0 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6 flex-wrap">
                        <div>
                          <span className="font-semibold">Período: </span>
                          {
                            data?.cheques_devolvidos_informados_usuario
                              ?.periodo_inicial
                          }{" "}
                          até
                          {
                            data?.cheques_devolvidos_informados_usuario
                              ?.periodo_final
                          }
                        </div>
                        <div>
                          <span className="font-semibold">Quantidade: </span>
                          {
                            data?.cheques_devolvidos_informados_usuario
                              ?.quantidade_total
                          }
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Banco
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Agência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Motivo/Alínea
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Informante
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.cheques_devolvidos_informados_usuario?.ultimas_ocorrencias?.cheque_devolvido_informado_usuario.map(
                              (cheque, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {cheque.data_registro}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.banco}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.agencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.motivo}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.informante}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                {/* <div>
            <div className="title-syncx mt-5 mb-4">Contumácia</div>
            {data?.CREDCADASTRAL.CONTUMACIA.OCORRENCIAS?.length > 0 ? (
              <>
                <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                  <div>
                    <span className="font-semibold">Quantidade: </span>
                    {data?.CREDCADASTRAL.CONTUMACIA.QUANTIDADE_OCORRENCIA}
                  </div>
                  <div>
                    <span className="font-semibold">Período: </span>
                    {
                      data?.CREDCADASTRAL.CONTUMACIA.DATA_PRIMEIRA_OCORRENCIA
                    }{" "}
                    até
                    {data?.CREDCADASTRAL.CONTUMACIA.DATA_ULTIMA_OCORRENCIA}
                  </div>
                </div>
                <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                  <Table striped>
                    <Table.Head>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Agência
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Banco
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Nº cheque Inicial
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Nº cheque Final
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Motivo devolução:
                      </Table.HeadCell>
                    </Table.Head>

                    <Table.Body className="divide-y">
                      {data?.CREDCADASTRAL.CONTUMACIA.OCORRENCIAS.map(
                        (contumacia, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              {contumacia.DATA_OCORRENCIA}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {contumacia.AGENCIA}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {contumacia.BANCO}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {contumacia.NUMERO_CHEQUE_INICIAL}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {+contumacia.NUMERO_CHEQUE_FINAL}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {contumacia.MOTIVO_DEVOLUCAO}
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </div>
              </>
            ) : (
              <Card className={`bg-green-200 mt-4`}>
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <IoAlertCircleOutline fontSize={20} />
                  </div>
                  <div>Nada consta</div>
                </div>
              </Card>
            )}
          </div> */}
                <div>
                  <div className="title-syncx mt-5 mb-4">
                    Histórico de consultas
                  </div>

                  {data?.consultas?.ultimas_consultas?.consulta?.length > 0 ? (
                    <>
                      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                        <div>
                          <span className="font-semibold">
                            Quantidade Total:{" "}
                          </span>
                          {data?.consultas?.quantidade_total}
                        </div>
                        <div>
                          <span className="font-semibold">Período: </span>
                          {data?.consultas?.periodo_inicial} até{" "}
                          {data?.consultas?.periodo_final}
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Empresa
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.consultas?.ultimas_consultas?.consulta.map(
                              (consult, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {consult.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {consult.razao_social}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
              </Card>
            </>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default DefineRiscoQuery;
