import React from "react";
import { Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

const DocDefineRisco = () => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Define Risco</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.syncx-api.com.br/api/define-risco/:CNPJ
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <div className="mt-4">
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CNPJ
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        Obs: Envie o token de autenticação no cabeçalho da requisição.
      </div>
      <CodeBlock
        text={`{name: 'teste',teste: 'teste'}`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocDefineRisco;
