import {
  Alert,
  Button,
  Card,
  Label,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { isValidCNPJ } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";

const LocalizaCNPJQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCNPJ(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_LOCALIZA_CNPJ}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData({});
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data.RETORNO);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Localiza CNPJ
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="18"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${!data && "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery />
              <div className="title-syncx mt-5">Informações da empresa:</div>
              <div className="flex flex-col md:flex-row gap-3 md:gap-20">
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">CNPJ: </span>
                    {data?.CADASTRAIS?.CNPJ}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome fantasia: </span>
                    {data?.CADASTRAIS?.NOME_FANTASIA}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Razão Social: </span>
                    {data?.CADASTRAIS?.RAZAO_SOCIAL}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Situação: </span>
                    {data?.CADASTRAIS?.SITUACAO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">CNAE: </span>
                    {data?.CADASTRAIS?.CNAE}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Data de abertura: </span>
                    {data?.CADASTRAIS?.DATA_ABERTURA}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Endereço: </span>
                    {data?.CADASTRAIS?.LOGRADOURO || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Cidade: </span>
                    {data?.CADASTRAIS?.CIDADE || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Bairro: </span>
                    {data?.CADASTRAIS?.BAIRRO || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">UF: </span>
                    {data?.CADASTRAIS?.UF || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">CEP: </span>
                    {data?.CADASTRAIS?.CEP || "Sem info."}
                  </div>
                </div>
              </div>

              <div>
                <div className="title-syncx mt-5 mb-4">
                  Informações dos sócios
                </div>
                {data?.SOCIOS ? (
                  <Timeline>
                    {Array.isArray(data?.SOCIOS?.SOCIO) ? (
                      <>
                        {data?.SOCIOS?.SOCIO?.map((referencia, index) => (
                          <Timeline.Item key={index}>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time>
                                Data de entrada: {referencia.DATA_ENTRADA}
                              </Timeline.Time>
                              <Timeline.Title>{referencia.NOME}</Timeline.Title>
                              <Timeline.Body>
                                <div className="flex items-center gap-1">
                                  <span>Qualificação:</span>
                                  <span>
                                    {referencia.QUALIFICACAO || "Sem info."}
                                  </span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span>Documento:</span>
                                  <span>
                                    {referencia.DOCUMENTO_SOCIO || "Sem info."}
                                  </span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span>Valor de participação:</span>
                                  <span>
                                    {referencia.VALOR_PARTICIPACAO ||
                                      "Sem info."}
                                  </span>
                                </div>
                              </Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        ))}
                      </>
                    ) : (
                      <Timeline.Item>
                        <Timeline.Point />
                        <Timeline.Content>
                          <Timeline.Time>
                            Ano base: {data?.SOCIOS?.SOCIO?.ANO_BASE}
                          </Timeline.Time>
                          <Timeline.Title>
                            {data?.SOCIOS?.SOCIO?.RAZAO_SOCIAL}
                          </Timeline.Title>
                          <Timeline.Body>
                            <div className="flex items-center gap-1">
                              <span>Profissão:</span>
                              <span>
                                {data?.SOCIOS?.SOCIO?.PROFISSAO || "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Data de admissão:</span>
                              <span>
                                {data?.SOCIOS?.SOCIO?.DATA_ADMISSAO ||
                                  "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Data de demissão:</span>
                              <span>
                                {data?.SOCIOS?.SOCIO?.DATA_DEMISSAO ||
                                  "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Motivo de desligamento:</span>
                              <span>
                                {data?.SOCIOS?.SOCIO?.MOTIVO_DESLIGAMENTO ||
                                  "Sem info."}
                              </span>
                            </div>
                          </Timeline.Body>
                        </Timeline.Content>
                      </Timeline.Item>
                    )}
                  </Timeline>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>

              <div>
                <div className="title-syncx mt-5 mb-4">Telefones móveis</div>
                {data?.TELEFONES_MOVEL ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Número
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Operadora
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Prioridade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.TELEFONES_MOVEL?.TELEFONE) ? (
                            <>
                              {data?.TELEFONES_MOVEL?.TELEFONE?.map(
                                (telefone, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {telefone.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.OPERADORA}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.PRIORIDADE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.NUMERO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.OPERADORA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.PRIORIDADE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <div className="title-syncx mt-5 mb-4">Telefones fixo</div>
                {data?.TELEFONES_FIXO ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Número
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Operadora
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Prioridade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.TELEFONES_FIXO?.TELEFONE) ? (
                            <>
                              {data?.TELEFONES_FIXO?.TELEFONE?.map(
                                (telefone, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {telefone.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.OPERADORA}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.PRIORIDADE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.TELEFONES_FIXO?.TELEFONE?.NUMERO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_FIXO?.TELEFONE?.OPERADORA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_FIXO?.TELEFONE?.PRIORIDADE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <div className="title-syncx mt-5 mb-4">E-mails</div>
                {data?.EMAILS ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            E-mail
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.EMAILS?.EMAIL) ? (
                            <>
                              {data?.EMAILS?.EMAIL?.map((email, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                    {email}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.EMAILS?.EMAIL}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>

              <div>
                <div className="title-syncx mt-5 mb-4">Funcionários</div>
                {data?.FUNCIONARIOS ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            CPF
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data admissão
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data demissão
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Ano base
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.FUNCIONARIOS?.DADOS) ? (
                            <>
                              {data?.FUNCIONARIOS?.DADOS?.map(
                                (funcionario, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {funcionario.NOME}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {funcionario.CPF}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {funcionario.DATA_ADMISSAO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {funcionario.DATA_DEMISSAO || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {funcionario.ANO_BASE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.FUNCIONARIOS?.DADOS?.NOME}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.FUNCIONARIOS?.DADOS?.CPF}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.FUNCIONARIOS?.DADOS?.DATA_ADMISSAO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.FUNCIONARIOS?.DADOS?.DATA_DEMISSAO ||
                                  "-"}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.FUNCIONARIOS?.DADOS?.ANO_BASE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
            </Card>
          ) : (
            <Card>
              <div role="status" className="w-full animate-pulse">
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default LocalizaCNPJQuery;
