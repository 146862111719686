import domtoimage from 'dom-to-image';
import { PDFDocument } from 'pdf-lib';

export const handleDownloadPdf = async (element) => {
  try {
    const dataUrl = await domtoimage.toPng(element);
    const img = new Image();
    img.src = dataUrl;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const pdfDoc = await PDFDocument.create();
    const pdfWidth = 595.28; // Largura da página A4 em pontos
    const pdfHeight = 841.89; // Altura da página A4 em pontos
    const imgWidth = img.width;
    const imgHeight = img.height;
    const scaleFactor = pdfWidth / imgWidth;

    const totalPages = Math.ceil((imgHeight * scaleFactor) / pdfHeight);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = imgWidth;
    canvas.height = imgHeight;

    ctx.drawImage(img, 0, 0, imgWidth, imgHeight);

    for (let i = 0; i < totalPages; i++) {
      const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

      const yOffset = (pdfHeight / scaleFactor) * i;

      const segment = ctx.getImageData(0, yOffset, imgWidth, pdfHeight / scaleFactor);
      const segmentCanvas = document.createElement('canvas');
      const segmentCtx = segmentCanvas.getContext('2d');
      segmentCanvas.width = imgWidth;
      segmentCanvas.height = pdfHeight / scaleFactor;

      segmentCtx.putImageData(segment, 0, 0);

      const segmentDataUrl = segmentCanvas.toDataURL('image/png');
      const pdfImage = await pdfDoc.embedPng(segmentDataUrl);
      
      page.drawImage(pdfImage, {
        x: 0,
        y: 0,
        width: pdfWidth,
        height: pdfHeight,
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    return blob;
  } catch (error) {
    console.error('Erro ao gerar o PDF:', error);
  }
};
