import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Card, Pagination, Table } from "flowbite-react";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { QUERIES } from "../../config/apiConfig";
import LastMonthsSelect from "../inputs/LastMonthsSelect";
import { formatToReal } from "../../utils/utils";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import EmptyTable from "./EmptyTable";

const ReportDetailedTable = () => {
  const [period, setPeriod] = useState(moment().format("YYYY-MM"));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  const {
    isLoading: isLoadingReportQueries,
    error: errorListReportQueries,
    data: listReportQueries,
  } = useQuery(["listReportQueries", period, currentPage], async () => {
    const firstDayOfMonth = moment(period, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const lastDayOfMonth = moment(period, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const { data } = await axios.get(
      `${QUERIES.GET_QUERIES_LIST}?startDate=${firstDayOfMonth}&endDate=${lastDayOfMonth}&page=${currentPage}`
    );

    setTotalPriceSum(data.data.totalPriceSum);
    setTotalCount(data.data.totalCount);
    setTotalPages(data.data.totalPages || 1);

    return data.data.data;
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [period]);

  return (
    <>
      <FilterToolbar
        title="Selecione o mês:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "var(--bg-black)" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <LastMonthsSelect onChange={(e) => setPeriod(e.target.value)} />
        </div>
      </FilterToolbar>
      <div className="overflow-x-scroll mt-4">
        <div className="flex gap-3 pb-3" style={{ minWidth: "500px" }}>
          <Card
            className="w-full bg-gray-700 relative"
            style={{ minWidth: "150px" }}
          >
            <div className="text-sm md:text-md lg:text-lg text-white">
              Valor Total:
            </div>
            <div className="text-2xl text-white font-semibold flex gap-2 items-center">
              {!isLoadingReportQueries ? (
                `${formatToReal(totalPriceSum)}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <MdOutlineAttachMoney className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
          </Card>
          <Card className="w-full bg-gray-700 relative overflow-hidden">
            <div className="text-sm md:text-md lg:text-lg text-white">
              Total de consultas:
            </div>
            <div className="text-2xl text-white font-semibold">
              {!isLoadingReportQueries ? (
                `${totalCount}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
          </Card>
        </div>
      </div>
      <small
        className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
        style={{ fontSize: 10 }}
      >
        <span>Deslize para o lado para visualizar todas as informações</span>{" "}
        <FaArrowsLeftRight />
      </small>
      {listReportQueries?.length > 0 ? (
        <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
          <Table striped>
            {listReportQueries?.length > 0 ? (
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Produto/Consulta
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Data/hora
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Valor da consulta
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Usuário
                </Table.HeadCell>
              </Table.Head>
            ) : (
              <></>
            )}

            <Table.Body className="divide-y">
              {!isLoadingReportQueries ? (
                <>
                  {listReportQueries?.length > 0 && (
                    <>
                      {listReportQueries.map((query, index) => (
                        <Table.Row
                          key={index}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {query.queryName}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {moment(query.createdAt).format("DD/MM/YY | HH:mm")}
                            h
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {formatToReal(query.price)}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {query.parameter}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                            {query.userName}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Table.Row
                      key={index}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                        <div
                          role="status"
                          className="max-w-sm animate-pulse inline"
                        >
                          <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <EmptyTable icon="search_off">
          {" "}
          Nenhuma consulta realizada neste período.
        </EmptyTable>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}
    </>
  );
};

export default ReportDetailedTable;
