import React, { useState } from "react";
import { Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import DocDefineRisco from "../documentations/DocDefineRisco";
import DocAcertaEssencial from "../documentations/DocAcertaEssencial";

const DocsTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(0);
  const listForms = [];

  return (
    <div className="flex flex-col lg:flex-row gap-2">
      <div className="p-4 flex flex-col gap-2 w-full md:w-1/4">
        <h1 className="title-syncx mb-2">Consultas</h1>
        <Card
          className={`card-query ${selectedQuery === 0 && "active"}`}
          onClick={() => setSelectedQuery(0)}
        >
          Define Risco
        </Card>
        <Card
          className={`card-query ${selectedQuery === 1 && "active"}`}
          onClick={() => setSelectedQuery(1)}
        >
          Acerta Essencial CPF
        </Card>
      </div>
      <div className="w-full md:w-3/4">
        {selectedQuery === 0 && <DocDefineRisco />}
        {selectedQuery === 1 && <DocAcertaEssencial />}
      </div>
    </div>
  );
};

export default DocsTable;
