import { Alert, Button, Modal } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import axios from "../../config/axiosInstance";
import { PAYMENTS } from "../../config/apiConfig";
import { useEffect, useState } from "react";

const ModalAddCredit = ({ isOpen, setOpenModal }) => {
  const [qrCode, setQrCode] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [errorPayment, setErrorPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handlePixPayment = async (data) => {
    try {
      setErrorPayment(null);
      setIsLoading(true);
      const dataPix = await axios.get(
        `${PAYMENTS.PIX}?valuePix=${data.valuePix}`
      );

      setQrCode(dataPix.data.data.encodedImage);
      setPaymentLink(dataPix.data.data.payload);
    } catch (error) {
      setErrorPayment(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset();
    setErrorPayment(null);
    setPaymentLink("");
    setQrCode("");
  }, [isOpen]);

  return (
    <>
      <Modal show={isOpen} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <div className="flex gap-2 justify-center items-center mb-5">
              <MdOutlinePayments className="h-8 w-8 text-gray-400 dark:text-gray-200" />
              <h3 className="text-lg font-normal text-gray-500 dark:text-gray-400">
                Adicionar créditos
              </h3>
            </div>
            <form onSubmit={handleSubmit(handlePixPayment)}>
              <Input
                type="number"
                step="0.01"
                name="valuePix"
                register={register}
                label="Valor"
                required={true}
              />
              <Button
                type="submit"
                className="bg-primary mt-4 mx-auto"
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                isProcessing={isLoading}
              >
                Gerar pagamento
              </Button>
            </form>
            {qrCode && (
              <div className="mt-4">
                <div>Pague com QR Code</div>
                <img
                  className="max-w-48 mx-auto"
                  src={`data:image/png;base64,${qrCode}`}
                />
                <div>ou</div>
                <div>Pague com código PIX</div>
              </div>
            )}

            {errorPayment && (
              <Alert color="failure" className="mt-2">
                <span className="font-medium">Ops!</span> Ocorreu um erro ao
                tentar gerar uma cobrança por PIX. Tente novamente mais tarde!
              </Alert>
            )}

            <div className="flex justify-center gap-4 mt-4">
              <Button color="gray" onClick={() => setOpenModal(false)}>
                Fechar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAddCredit;
